<script>
import GlobalVue from '@helper/Global.vue';
import BaseVue from '@frontend/Base.vue';
import Gen from '@helper/Gen';

export default {
    extends: GlobalVue,
    data() {
        return {
          newProduct:[],
          data:[],
          newback:{}
        }
    },
    watch: {
      $route() {
        this.getProduct()
        this.refreshMeta()
      }
    },
    async mounted() {
        this.$set(this.$root, 'page', this)
        this.getProduct()
        this.refreshMeta()
    },
    methods: {
      getProduct() {
        Gen.apirest("/product",{}, (err, resp) => {
          if (err) console.log(err)
          this.newProduct = resp.newProduct
          this.data = resp.data
          this.newback = resp.newback
          this.getProductList()
          setTimeout(()=>{            
            SEMICOLON.widget.carousel();
            AOS.init({ once: true });
          }, 500)
        })
      },
      getProductList() {
        Gen.apirest("/product-list",{}, (err, resp) => {
          if (err) console.log(err)
          this.data = resp.data
          setTimeout(()=>{            
            SEMICOLON.widget.carousel();
            AOS.init({ once: true });
          }, 500)
        })
      },
      refreshMeta(){
        Gen.rest(this.$route.path, {metainfo:true},(err,resp)=>{
          if(err) return console.error(err)
          $("title").text(resp.meta_title)
          $("meta[property='og:image']").attr('content', resp.ss_img)
          $("meta[property='og:title']").attr('content', resp.meta_title)
          $("meta[name='description']").attr('content', resp.ss_meta_desc)
          $("meta[property='og:description']").attr('content', resp.ss_meta_desc)
          $("meta[name='keywords']").attr('content', resp.ss_meta_keyword)
          $("meta[name='author']").attr('content', resp.ss_author)
          $("meta[property='og:site_name']").attr('content', resp.ss_sitename)
          $("meta[property='url']").attr('content', resp.ss_url)
          $("link[rel='canonical']").attr('content', resp.ss_url)
          this.$root.meta = resp
        })
      },
    },
};
</script>

<template>
  <section id="content">
    <div class="content-wrap py-0">
      <section class="section bg-fixer light-wave bg_blue_general">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12" v-if="newProduct.length">
              <div class="heading-block mb-0 center" data-aos="fade-up">
                <h2>{{web.lbl_new_product}}</h2>
              </div>
              <div id="oc-clients" class="owl-carousel image-carousel carousel-widget" data-margin="60"
                :data-loop="newProduct.length < 5 ? 'false' : 'true'" data-nav="true" data-autoplay="5000" data-pagi="false" data-items-xs="2"
                data-items-sm="3" data-items-md="4" data-items-lg="5" data-items-xl="5">
                <div v-for="(np,kp) in newProduct" class="oc-item" data-aos="fade-up" :data-aos-delay="((kp+1)*150)" :key="kp">
                  <div class="product_wrapper">
                    <router-link :to="{name:'ProductDetail',params:{cat:np.apc_slug,slug:np.ap_slug}}"><img :src="uploader(np.ap_img)" :alt="np.ap_name"></router-link>
                    <div class="product_desc">
                      <h3>{{np.ap_name}}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-md-8 text-center" v-else>
              <div class="notfound notfound--products">
                <img :src="assets('fo_images', 'search-no-products.png')" alt="" class="notfound__img">
                <h4 class="notfound__title">Belum tersedia produk baru.</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-bottom" :style="'background-image:url('+uploader(newback.as_val)+')'"></div>
      </section>

      <section class="section bg-fixer bg_blue_general" v-for="(v,k) in data" :key="k" :style="v.cat_id == 1 ? 'background-image:linear-gradient(to bottom, #9ed6f5, transparent 25%), url('+uploader(v.cat_img_back)+')' : ''" :class="k != data.length-1 ? 'light-wave':''">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-md-7">
              <div class="form-row align-items-center justify-content-center">
                <div class="col-7 col-lg-12 order-2 order-lg-0">
                  <div class="wrap_brand" data-aos="zoom-out">
                    <img :src="uploader(v.cat_img_logo)" :alt="v.cat_name"/>
                  </div>
                </div>
                <div class="col-5 col-lg-12" v-if="v.cat_img_tagline != null">
                  <div class="brand_tagline" data-aos="zoom-out" data-aos-delay="150">
                    <img :src="uploader(v.cat_img_tagline)" :alt="v.cat_name"/>
                  </div>
                </div>
                <div class="col-12 text-center order-3 order-md-2">
                  <a v-if="v.cat_link != null" :href="v.cat_link" target="_blank" class="cta cta-primary cta--reveal mt-3 cta--longer"><span>Kunjungi Website</span><i class="icon-line-arrow-right"></i></a>
                </div>
              </div>
            </div>
            <div class="col-lg-9" v-if="v.prod.length">
              <div class="row justify-content-center justify-content-md-start product-row">
                <div class="col-md-3 col-6" data-aos="fade-up" :data-aos-delay="(kp%4)*150" v-for="(vp,kp) in v.prod" :key="kp">
                  <div class="product_wrapper pad_10">
                    <router-link :to="{name:'ProductDetail',params:{cat:vp.apc_slug,slug:vp.ap_slug}}"><img :src="uploader(vp.ap_img)" :alt="vp.ap_name"></router-link>
                    <div class="product_desc">
                      <h3>{{vp.ap_name}}</h3>
                    </div>
                  </div>
                </div>
                <div class="clear-fix"></div>
                <div v-if="v.count == 8" class="col-md-12 text-center align-self-center">
                  <router-link :to="{name:'ProductCat',params:{cat:v.cat_slug}}" class="cta cta-primary cta--reveal my-4">
                    <span>Show More</span>
                    <i class="icon-line-arrow-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
            <div class="col-lg-9 text-center" v-else>
              <div class="notfound notfound--products">
                <img :src="assets('fo_images', 'search-no-products.png')" alt="" class="notfound__img">
                <h4 class="notfound__title">{{web.lbl_no_product}}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-bottom" :style="v.cat_id == 1 ? '' : 'background-image:url('+uploader(v.cat_img_back)+')'"></div>
      </section>
    </div>
  </section>
</template>