const BoConfig = {
    sidebar: [
        {name:"Main", divider:true},
        {name:"Dashboard",link:"BoDashboard",icon:"icon-speedometer"},  
        {name:"Website Management", divider:true},
        {name:"Home",link:"BoHome",icon:"ti-home"},
        {name:"About HiLo",link:"BoAbout",icon:"icon-docs"},
        {name:"Products", icon:"icon-drawar",childs:[
            {name:'Brands',link:'BoProductCat'},
            {name:'Products',link:'BoProduct'}
        ]},
        {name:"Health Info", icon:"icon-book-open",childs:[
            {name:'Category',link:'BoInfoCat'},
            {name:'Article',link:'BoInfo'}
        ]},
        {name:"News & Event", icon:"icon-doc",link:'BoNews'},
        {name:"Contact", icon:"icon-speech",link:'BoContact'},
        {name:"Quiz", icon:"icon-question",link:'BoQuiz'},
        {name:"Web Settings", icon:"icon-settings",childs:[
            {name:"Static SEO",link:"StaticSeo"},
            {name:"Legal Page",link:"Legal"},
            {name:"Content Settings",link:"WebSettings"},
            {name:"Email Template",link:"MrTemplate"},
        ]},
        {name:"CMS Management", divider:true},
        {name:"User Management", icon:"icon-people",childs:[
            {name:"Users",link:"BoUsers"},
            {name:"User Level",link:"BoUserLevel"},
        ]},
    ],
}
export default BoConfig